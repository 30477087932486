import { fetchJSON } from '#js/components/http'
import { gtag } from '#js/components/utils'

const dataLayer = window.dataLayer || []

/**
 * Store and handles the cookie consent state.
 *
 * Load the current state either from a json object (database value)
 * provided as a script or from a cookie.
 *
 * Sends the cookie consent state to the api, when
 * it could not load it's state from the json object.
 *
 * Pushed updates to the datalayer for GTM.
 */
export default class CookieConsentState {
  constructor () {
    this.state = {
      analytics_storage: null,
      ad_storage: null
    }
    this.load()
    if (this.state.analytics_storage && this.state.ad_storage) {
      this.pushToDataLayer()
    }
  }

  set (newState, callback) {
    if (newState.analytics_storage && newState.ad_storage) {
      this.state.analytics_storage = newState.analytics_storage
      this.state.ad_storage = newState.ad_storage
      this.saveToCookie()
      this.pushToDataLayer()
      this.saveToDatabase(callback)
    } else {
      throw new Error(
        'New cookie consent state is in the wrong format or not all values where set.'
      )
    }
  }

  load () {
    const databaseConsentScript = document.getElementById('cookie-consent')
    let databaseConsent = {}
    if (databaseConsentScript) {
      databaseConsent = JSON.parse(document.getElementById('cookie-consent').textContent)
      this.state.analytics_storage = databaseConsent.analytics_storage ? 'granted' : 'denied'
      this.state.ad_storage = databaseConsent.ad_storage ? 'granted' : 'denied'
    } else {
      const userConsentCookie = document.cookie.split(';').find((item) => item.trim().startsWith('_voicc='))
      if (userConsentCookie) {
        this.state.analytics_storage = userConsentCookie.includes('analytics_storage') ? 'granted' : 'denied'
        this.state.ad_storage = userConsentCookie.includes('ad_storage') ? 'granted' : 'denied'
        this.saveToDatabase(() => {})
      }
    }
  }

  saveToDatabase (callback) {
    const params = new URLSearchParams()
    params.append('ad_storage', this.state.ad_storage)
    params.append('analytics_storage', this.state.analytics_storage)
    fetchJSON(
      `/accounts/user/cookie-consent?${params.toString()}`
    ).then(data => {
      callback(data)
    })
  }

  pushToDataLayer () {
    // eslint-disable-next-line no-undef
    gtag('consent', 'update', this.state)
    dataLayer.push({ event: 'update_consent' })
  }

  parseConsentStateForCookie (consentState) {
    const storages = []
    if (consentState.analytics_storage === 'granted') {
      storages.push('analytics_storage')
    }
    if (consentState.ad_storage === 'granted') {
      storages.push('ad_storage')
    }
    return storages.join('+')
  }

  saveToCookie () {
    // Save the consent state as cookie for anonymous users. When they log in, we will use
    // it to remember the state and save it to the database.
    document.cookie = `_voicc=${this.parseConsentStateForCookie(this.state)};expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=${window.location.hostname};path=/;SameSite=Strict`
  }

  isSet () {
    return this.state.analytics_storage !== null && this.state.ad_storage !== null
  }

  hasFullConsent () {
    return this.state.analytics_storage === 'granted' && this.state.ad_storage === 'granted'
  }
}
